body {
  margin: 0px; }

a {
  color: #c95c66;
}

#home_header {
  background: #F9FCFF url("/images/_bg/bg-05.jpg") center center no-repeat;
}

#specials_header {
  background: #F9FCFF url("/images/_bg/bg-05.jpg") center center no-repeat;
}

.dark_footer {
  color: black;
  background: #F9FCFF url("/images/_bg/bg-00.jpg") center center no-repeat;
}

#specials .specials_wrap .specials_img {
  min-height: 600px;
      background-size: cover; }
      @media (min-width: 992px) {
        #specials .specials_wrap .specials_img {
          min-height: 420px; } }
      @media (max-width: 575.98px) {
        #specials .specials_wrap .specials_img {
          min-height: auto; } }
      @media (min-width: 768px) and (max-width: 991.98px) {
        #specials .specials_wrap .specials_img {
          min-height: 300px;
          background-size: cover;
          background-position: center left; } 
}
